import './App.css';
import { AudioRecorder } from 'react-audio-voice-recorder';
import { useState } from 'react';
import { Card } from 'react-bootstrap';
import { useEffect } from 'react';

function App() {
  const [transcriptions, setTranscriptions] = useState([]);
  const [apiKey, setApiKey] = useState('');
  useEffect(() => {
    let key = sessionStorage.getItem('whisperApiKey');
    if(key == null) {
      key = ""
    }
    key = window.prompt("Please enter your whisper API key", key);
    if(key){
    sessionStorage.setItem('whisperApiKey', key);
    }
    setApiKey(key);
  },[]);
  const addAudioElement = (blob) => {
    const url = URL.createObjectURL(blob);
    console.log(blob);
    let formData = new FormData();
    const audiofile = new File([blob], 'audiofile.webm', {
      type: 'audio/webm',
    });
    // var file = new File([blob], 'nameASAS', { type: blob.type });
    formData.append('file', audiofile);
    fetch(`https://verto-whisper-dev.openai.azure.com/openai/deployments/whisper/audio/transcriptions?api-version=2023-09-01-preview`, {
      method: 'POST',
      headers: {
        'api-key': apiKey,
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong');
      })
      .then((data) => {
        console.log(data)
        let newTranscription = {
          audioSrc: url,
          text: data.text,
        };
        setTranscriptions([...transcriptions, newTranscription]);
      })
      .catch((error) => {
        let newTranscription = {
          audioSrc: url,
          text: 'Something went wrong transcribing the audio. Please check your api key and try again.'
        };
        setTranscriptions([...transcriptions, newTranscription]);
      });
  };

  return (
    <div class="container">
      <div>
        <AudioRecorder onRecordingComplete={(blob) => addAudioElement(blob)} downloadOnSavePress={false} />
      </div>
      <div class="recordings">
        {transcriptions.map((transcription, index) => {
          return (
            <Card>
              <Card.Header>Recording {index + 1}</Card.Header>
              <Card.Body>
                <Card.Text>Text: {transcription.text}</Card.Text>
                <audio src={transcription.audioSrc} controls />
              </Card.Body>
            </Card>
          );
        })}
      </div>
    </div>
  );
}

export default App;
